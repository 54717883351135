<template>
  <div class="register">
    <div class="register-cont">
      <!-- <img
        class="register-cont-title"
        src="../assets/register/register_title.png"
        alt=""
      /> -->
      <div class="register-cont-cont">
        <!-- <div class="rcc-register">注册</div> -->
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="160px"
          class="rcc-ruleForm"
        >
          <el-form-item label="旧密码：" prop="oldPassword">
            <el-col :span="16"
              ><el-input v-model="ruleForm.oldPassword"></el-input
            ></el-col>
          </el-form-item>
          <el-form-item label="新密码：" prop="newPassword">
            <el-col :span="16"
              ><el-input v-model="ruleForm.newPassword"></el-input
            ></el-col>
          </el-form-item>
          <!-- <el-form-item label="确认新密码：" prop="password">
            <el-col :span="16"
              ><el-input v-model="ruleForm.password"></el-input
            ></el-col>
          </el-form-item> -->

          <div class="btn">
            <el-button type="primary" @click="submitForm('ruleForm')"
              >保存</el-button
            >
            <el-button @click="resetForm('ruleForm')">重置</el-button>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import cross from "../utils/cross";
import imgUrl from "../../public/avatar.png";
import Vue from "vue";
import qs from "qs";
import axios from "axios";

export default {
  name: "Register",
  data() {
    // var validateOld = (rule, value, callback) => {
    //   if (!value) {
    //     return callback(new Error("请输入旧密码"));
    //   }
    //   const newPasswords = this.ruleForm.newPassword;
    //   if (value && newPasswords) {
    //     if (value == newPasswords) {
    //       callback(new Error("新密码不能和旧密码一样"));
    //     } else {
    //       callback();
    //     }
    //   }
    // };
    var validateOld = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入新密码"));
      }
      const oldPasswords = this.ruleForm.oldPassword;
      if (value && oldPasswords) {
        if (value == oldPasswords) {
          callback(new Error("新密码不能和旧密码一样"));
        } else {
          callback();
        }
      }
    };

    return {
      codeUrl: "",
      ruleForm: {
        oldPassword: "",
        newPassword: "",
      },
      options: [],
      rules: {
        oldPassword: [
          { required: true, message: "请输入旧密码", trigger: "blur" },
        ],
        newPassword: [
          { required: true, validator: validateOld, trigger: "blur" },
        ],
      },
      userData: {},
      isLoading: false,
      loading: false,
      // 验证码开关
      captchaEnabled: true,
      // 注册开关
      register: false,
      redirect: undefined,
    };
  },
  watch: {
    $route: {
      handler: function (route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true,
    },
  },
  created() {
    // 从 localStorage 中读取字符串
    const userString = localStorage.getItem("user");

    // 使用 JSON.parse() 将字符串转换回对象
    this.userData = JSON.parse(userString);
    // this.initTreeFun();
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.ruleForm.newPassword=this.ruleForm.newPassword.replace(/#/g, "%23")
          let url =
            process.env.VUE_APP_BASE_API +
            "/f/userInterface/modifyPwd?oldPassword=" +
            this.ruleForm.oldPassword +
            "&newPassword=" +
            this.ruleForm.newPassword +
            "&userId=" +
            this.userData.id;

          axios.post(url).then((res) => {
            if (res.data == "修改成功") {
              this.$message({
                message: "修改密码成功",
                type: "success",
              });
              this.$refs[formName].resetFields();
            } else {
              this.$message({
                message: res.data,
                type: "error",
              });
            }

            
          });
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.register {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  .register-cont {
    display: flex;
    flex-direction: column;
    // justify-content: center;
    // align-items: center;
    width: calc(100vw * 1030 / 1920);
    height: calc(100vh * 900 / 1080);
    margin-top: calc(100vh * 20 / 1080);
    .register-cont-title {
      width: calc(100vw * 1000 / 1920);
      margin-bottom: calc(100vh * 40 / 1080);
    }
    .register-cont-cont {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: calc(100vw * 1070 / 1920);
      height: calc(100vh * 632 / 1080);
      // min-width: 1070px;
      // min-height: 445px;
      background-color: #fff;
      border-radius: calc(100vw * 20 / 1920);
      box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.1);
      .btn {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .rcc-register {
        font-size: 20px;
        margin-bottom: calc(100vh * 30 / 1080);
      }
      .rcc-ruleForm {
        width: calc(100vw * 800 / 1920);
      }
    }
  }
}
</style>
